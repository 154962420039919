/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2020. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
import React, { Component } from 'react';
import {
  ApplicationHeaderComponent,
  ApplicationFooter,
  CompatibilityHandler,
} from '@spm/universal-access-ui';
import PropTypes from 'prop-types';

/** Handles ... */
class CompatibilityInit extends Component {
  /**
   * Creates an instance of class and loads the optional config/browser.config.js.
   *
   * @param {any} props the props for the component
   * @memberof CompatibilityInit
   */
  constructor(props) {
    super(props);

    this.state = {};
    // Build a context to check whether the config exists
    const req = require.context('../config', false, /^\.\/browser.config.js$/);
    // if it is found load the browser config
    if (req.keys().length > 0) {
      this.state.browsers = req(req.keys()[0]).default;
    } else {
      // eslint-disable-next-line no-console
      console.debug('config/browser.config.js not found');
    }
  }

  /**
   * IntlInit component entry point.
   */
  render() {
    return (
      <CompatibilityHandler
        browsers={this.state.browsers}
        footer={<ApplicationFooter />}
        header={
          <ApplicationHeaderComponent
            isALinkedUser={() => false}
            isAppealsEnabled={false}
            isEmpty={false}
          />
        }
      >
        {this.props.children}
      </CompatibilityHandler>
    );
  }
}

CompatibilityInit.propTypes = {
  children: PropTypes.node,
};

CompatibilityInit.defaultProps = {
  children: null,
};

export default CompatibilityInit;
