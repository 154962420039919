import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import translations from './MOApplicationFooterMessages';
import { URL } from './constants';
import { Column, Grid, Image, Link } from '@govhhs/govhhs-design-system-react';
import logo from '../../images/mogov_logo_footer.png'

const MOFooterLink = ({ text, url }) => {
  return (
    <p>
      <Link to={url} target="_blank" type="external">{text}</Link>
    </p>
  )
}

const MOApplicationFooterComponent = ({ intl }) => {

  const { formatMessage } = intl;

  return (
    <div className="mo-footer">
      <Grid>
        <Column width="1/3">
          <h1>{formatMessage(translations.aboutTheAgencyHeader)}</h1>
          <MOFooterLink url={URL.ABOUT_DSS} text={formatMessage(translations.aboutDSS)} />
          <MOFooterLink url={URL.ABOUT_FSD} text={formatMessage(translations.aboutFSD)} />
          <MOFooterLink url={URL.OUR_DIVISIONS} text={formatMessage(translations.ourDivisions)} />
          <MOFooterLink url={URL.DSS_MO_GOV} text={formatMessage(translations.dssMOGov)} />
          <MOFooterLink url={URL.STATS_REPORTS} text={formatMessage(translations.statsReports)} />
          <MOFooterLink url={URL.NEWS_MEDIA} text={formatMessage(translations.newsMedia)} />
          <MOFooterLink url={URL.CAREERS} text={formatMessage(translations.careers)} />
          <MOFooterLink url={URL.STRATEGIC} text={formatMessage(translations.strategic)} />
          <MOFooterLink url={URL.PHONE_NUMBERS} text={formatMessage(translations.phoneNumbers)} />
          <MOFooterLink url={URL.NONDISCRIMINATION} text={formatMessage(translations.nondiscrimination)} />
          <MOFooterLink url={URL.NONDISCRIMINATION_SPANISH} text={formatMessage(translations.nondiscriminationSpanish)} />
        </Column>
        <Column width="1/3">
          <h1>{formatMessage(translations.popularLinksHeader)}</h1>
          <MOFooterLink url={URL.FRAUD} text={formatMessage(translations.fraud)} />
          <MOFooterLink url={URL.ENERGY} text={formatMessage(translations.energy)} />
          <MOFooterLink url={URL.CHILD_CARE} text={formatMessage(translations.childCare)} />
          <MOFooterLink url={URL.MEETINGS} text={formatMessage(translations.meetings)} />
          <MOFooterLink url={URL.PRIVACY_FORMS} text={formatMessage(translations.privacyForms)} />
        </Column>
        <Column width="1/3">
          <h1>{formatMessage(translations.resourcesHeader)}</h1>
          <MOFooterLink url={URL.COVID} text={formatMessage(translations.covid)} />
          <MOFooterLink url={URL.UNITED_WAY} text={formatMessage(translations.unitedWay)} />
          <MOFooterLink url={URL.REGISTER_TO_VOTE} text={formatMessage(translations.registerToVote)} />
          <MOFooterLink url={URL.INFLUENZA} text={formatMessage(translations.influenza)} />
          <MOFooterLink url={URL.HEALTH_BENEFIT_ACCOUNT} text={formatMessage(translations.healthBenefitAccount)} />
          <MOFooterLink url={URL.PRESUMPTIVE_ELIGIBILITY} text={formatMessage(translations.preumptiveEligibility)} />
          <MOFooterLink url={URL.GET_VIEWERS} text={formatMessage(translations.getViewers)} />
        </Column>
      </Grid>
      <div className="mo-agency-details">
        <Grid>
          <Column width="1/3" className="mo-agency-official">
            <div className="mo-agency-official">
              <p>{formatMessage(translations.governorTitle)}</p>
              <Link to={URL.GOVERNOR_URL} target="_blank" type="external">{formatMessage(translations.governorName)}</Link>
            </div>
          </Column>
          <Column width="1/3">
            <Link className="mo-gov-link" to={URL.MO_GOV} target="_blank" type="external">
                <Image src={logo} fallback={<span>mo.gov</span>} alt="mo.gov" />
            </Link>
          </Column>
          <Column width="1/3">
            <div className="mo-agency-official">
              <p>{formatMessage(translations.directoryTitle)}</p>
              <Link to={URL.DIRECTOR_URL} target="_blank" type="external">{formatMessage(translations.directoryName)}</Link>
            </div>
          </Column>
        </Grid>
      </div>
      <div className="mo-footer-info">
        <Link to={URL.PRIVACY_POLICY} target="_blank" type="external">{formatMessage(translations.privacyPolicy)}</Link>
        <Link to={URL.ACCESSIBILITY} target="_blank" type="external">{formatMessage(translations.accessibility)}</Link>
        <Link to={URL.CONTACT_US} target="_blank" type="external">{formatMessage(translations.contactUs)}</Link>
        <Link to={URL.SITE_MAP} target="_blank" type="external">{formatMessage(translations.siteMap)}</Link>
        <Link to={URL.PROPOSED_RULES} target="_blank" type="external">{formatMessage(translations.proposedRules)}</Link>
        <Link to={URL.COMMENT_ON_RULES} target="_blank" type="external">{formatMessage(translations.commentOnRules)}</Link>
      </div>
      <div className="mo-eeo-aids">
        <p>{formatMessage(translations.equalOpportunity)}</p>
        <p>{formatMessage(translations.auxiliaryAids)}</p>
      </div>
    </div >
  );
};

MOApplicationFooterComponent.propTypes = {
  intl: PropTypes.object.isRequired
};

export default withRouter(injectIntl(MOApplicationFooterComponent));
