const URL = {
  // BEGIN About the Agency column
  ABOUT_DSS: 'https://dss.mo.gov/ddo/',
  ABOUT_FSD: 'https://mydss.mo.gov/intro',
  OUR_DIVISIONS: 'https://dss.mo.gov/dssdiv.htm',
  DSS_MO_GOV: 'https://dss.mo.gov/',
  STATS_REPORTS: 'https://dss.mo.gov/rr.htm',
  NEWS_MEDIA: 'https://dss.mo.gov/press/',
  CAREERS: 'https://dss.mo.gov/hrc/jobs/',
  STRATEGIC:'https://strategicchange.mo.gov/agency/department-social-services',
  PHONE_NUMBERS: 'https://dss.mo.gov/toll.htm',
  NONDISCRIMINATION: 'https://dss.mo.gov/files/missouri-nondiscrimination-policy-statement.htm',
  NONDISCRIMINATION_SPANISH: 'https://dss.mo.gov/files/nondiscrimination-policy-statement-sp.htm',
  // END About the Agency column
  // BEGIN Popular Links column
  FRAUD: 'https://dss.mo.gov/dls/public-assistance-fraud-form.htm',
  ENERGY: 'https://mydss.mo.gov/utility-assistance',
  CHILD_CARE: 'https://dss.mo.gov/cd/child-care/',
  MEETINGS: 'https://www.mo.gov/meetings',
  PRIVACY_FORMS: 'https://dss.mo.gov/hipaa/',
  // END Popular Links column
  // BEGIN Resources column
  OPIOIDS: 'https://dss.mo.gov/mhd/opioid.htm',
  COVID: 'https://dss.mo.gov/covid-19/',
  UNITED_WAY: 'https://www.211helps.org/',
  REGISTER_TO_VOTE: 'https://sos.mo.gov/elections/s_default.asp?id=voter',
  INFLUENZA: 'https://health.mo.gov/living/healthcondiseases/communicable/influenza/',
  HEALTH_BENEFIT_ACCOUNT: 'https://mydssapp.mo.gov/',
  PRESUMPTIVE_ELIGIBILITY: 'https://mydss.mo.gov/pe-resources-for-providers',
  GET_VIEWERS: 'https://get.adobe.com/reader/',
  // END Resources column
  // BEGIN Agency Details
  GOVERNOR_URL: 'https://governor.mo.gov/',
  MO_GOV: 'https://mo.gov',
  DIRECTOR_URL: 'https://dss.mo.gov/',
  // END Agency Details
  // BEGIN Footer Info
  PRIVACY_POLICY: 'https://mo.gov/privacy-policy',
  ACCESSIBILITY: 'https://mo.gov/accessibility',
  CONTACT_US: 'https://dss.mo.gov/contactdss.htm',
  SITE_MAP: 'https://dss.mo.gov/atoz.htm',
  PROPOSED_RULES: 'https://dss.mo.gov/proposed-rules.htm',
  COMMENT_ON_RULES: 'https://dssruletracker.mo.gov/dss-proposed-rules/',
  // END Footer Info
}

export { URL };
