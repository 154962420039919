/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
/**
 * Combine all reducers in this file and export the combined reducers.
 * If we were to do this in store.js, reducers wouldn't be hot re-loadable.
 */

import SampleModuleReducers from './SampleModule/reducers';

import ForgotPasswordModuleReducers from './ForgotPasswordModule/reducers';

import RIDPModuleReducers from './RIDPModule/reducers';

import ContactInformationModuleReducers from './ContactInformationModule/reducers';

/**
 * Creates the main reducer which combines all the reducers from the modules
 * and any provided reducers supplied from the wrapping application.
 *
 * Provided reducers are ignored where there is a clash in naming.
 *
 * @namespace UAReduxReducers
 */
export default {
  /**
   * Holds the application configuration state within the store.
   * @memberof UAReduxReducers
   * @property
   */
  UASampleModule: SampleModuleReducers.reducers,

  /**
   * Holds the application configuration state within the store.
   * @memberof UAReduxReducers
   * @property
   */
  UAForgotPasswordModule: ForgotPasswordModuleReducers.reducers,

  /**
   * Holds the application configuration state within the store.
   * @memberof UAReduxReducers
   * @property
   */
  UARIDPModule: RIDPModuleReducers.reducers,

  /**
   * Holds the application configuration state within the store.
   * @memberof UAReduxReducers
   * @property
   */
  UAContactInformationModule: ContactInformationModuleReducers.reducers,
};
