/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
import { combineReducers } from 'redux';
import { ReduxUtils } from '@spm/core';
import {
  FETCH_MO_USER_VALIDATED,
  POST_MO_VERIFY_USER,
  POST_MO_SECONDARY_VERIFY_USER,
} from './actionTypes';

/**
 * Updates the details in the 'RIDPModule' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const fetchMoUserValidatedReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, FETCH_MO_USER_VALIDATED);

/**
 * Updates the indicator of creation of details in the 'RIDPModule' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingFetchMoUserValidatedReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, FETCH_MO_USER_VALIDATED);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const fetchMoUserValidatedErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, FETCH_MO_USER_VALIDATED);

/**
 * Updates the details in the 'RIDPModule' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postMoVerifyUserReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, POST_MO_VERIFY_USER);

/**
 * Updates the indicator of creation of details in the 'RIDPModule' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostMoVerifyUserReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, POST_MO_VERIFY_USER);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postMoVerifyUserErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, POST_MO_VERIFY_USER);

/**
 * Updates the details in the 'RIDPModule' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postMoSecondaryVerifyUserReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, POST_MO_SECONDARY_VERIFY_USER);

/**
 * Updates the indicator of creation of details in the 'RIDPModule' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostMoSecondaryVerifyUserReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, POST_MO_SECONDARY_VERIFY_USER);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postMoSecondaryVerifyUserErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, POST_MO_SECONDARY_VERIFY_USER);

const reducers = combineReducers({
  fetchMoUserValidated: fetchMoUserValidatedReducer,

  isFetchingFetchMoUserValidated: isFetchingFetchMoUserValidatedReducer,
  fetchMoUserValidatedError: fetchMoUserValidatedErrorReducer,

  postMoVerifyUser: postMoVerifyUserReducer,

  isFetchingPostMoVerifyUser: isFetchingPostMoVerifyUserReducer,
  postMoVerifyUserError: postMoVerifyUserErrorReducer,

  postMoSecondaryVerifyUser: postMoSecondaryVerifyUserReducer,

  isFetchingPostMoSecondaryVerifyUser: isFetchingPostMoSecondaryVerifyUserReducer,
  postMoSecondaryVerifyUserError: postMoSecondaryVerifyUserErrorReducer,
});

export default { reducers };
