/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
/**
 * Contains the selectors that select state from the 'RIDPModule' store object.
 * @namespace RIDPModuleSelectors
 */
export default class selectors {
  /**
   * @private
   */
  static moduleIdentifier = 'UARIDPModule';

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof RIDPModuleSelectors
   */
  static fetchMoUserValidated = state =>
    state[selectors.moduleIdentifier].fetchMoUserValidated.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof RIDPModuleSelectors
   */
  static isFetchingFetchMoUserValidated = state =>
    state[selectors.moduleIdentifier].isFetchingFetchMoUserValidated.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof RIDPModuleSelectors
   */
  static fetchMoUserValidatedError = state =>
    state[selectors.moduleIdentifier].fetchMoUserValidatedError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof RIDPModuleSelectors
   */
  static postMoVerifyUser = state => state[selectors.moduleIdentifier].postMoVerifyUser.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof RIDPModuleSelectors
   */
  static isFetchingPostMoVerifyUser = state =>
    state[selectors.moduleIdentifier].isFetchingPostMoVerifyUser.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof RIDPModuleSelectors
   */
  static postMoVerifyUserError = state =>
    state[selectors.moduleIdentifier].postMoVerifyUserError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof RIDPModuleSelectors
   */
  static postMoSecondaryVerifyUser = state =>
    state[selectors.moduleIdentifier].postMoSecondaryVerifyUser.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof RIDPModuleSelectors
   */
  static isFetchingPostMoSecondaryVerifyUser = state =>
    state[selectors.moduleIdentifier].isFetchingPostMoSecondaryVerifyUser.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof RIDPModuleSelectors
   */
  static postMoSecondaryVerifyUserError = state =>
    state[selectors.moduleIdentifier].postMoSecondaryVerifyUserError.payload;
}
