/*
 * Licensed Materials - Property of IBM
 *
 * Copyright IBM Corporation 2018. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'Signup_Title',
    defaultMessage: 'Sign up',
  },
  subtitle: {
    id: 'Signup_Subtitle',
    defaultMessage: "To set up a new account we'll need you to enter the following details",
  },
  firstName: {
    id: 'Signup_LabelFirstName',
    defaultMessage: 'First Name',
  },
  surname: {
    id: 'Signup_LabelSurname',
    defaultMessage: 'Last Name',
  },
  emailAddress: {
    id: 'Signup_LabelEmailAddress',
    defaultMessage: 'Email',
  },
  emailAddressHint: {
    id: 'Signup_LabelEmailAddressHint',
    defaultMessage: 'You will use this email to log in and receive updates',
  },
  username: {
    id: 'Signup_LabelUsername',
    defaultMessage: 'Username',
  },
  noEmail: {
    id: 'Signup_LabelNoEmail',
    defaultMessage: "I don't have an email address",
  },
  password: {
    id: 'Signup_LabelPassword',
    defaultMessage: 'Password',
  },
  confirmPassword: {
    id: 'Signup_LabelConfirmPassword',
    defaultMessage: 'Confirm Password',
  },
  secretQuestionType: {
    id: 'Signup_LabelSecretQuestionType',
    defaultMessage: 'Security question',
  },
  secretQuestionTypePlaceholder: {
    id: 'Signup_LabelSecretQuestionType_Placeholder',
    defaultMessage: 'Select a security question...',
  },
  secretQuestionAnswer: {
    id: 'Signup_LabelSecretQuestionAnswer',
    defaultMessage: 'Security question answer',
  },
  termsAndConditions: {
    id: 'Signup_LabelTermsAndConditions',
    defaultMessage: 'I agree to the {link}',
  },
  termsAndConditionsLink: {
    id: 'Signup_LabelTermsAndConditionsLink',
    defaultMessage: 'terms and conditions',
  },
  submitButton: {
    id: 'Signup_LabelSubmitButton',
    defaultMessage: 'Sign Up',
  },
  alreadyHaveAnAccount: {
    id: 'Signup_LabelAlreadyHaveAnAccount',
    defaultMessage: 'Already have an account? {link}',
  },
  firstNameMissing: {
    id: 'Signup_LabelFirstName_Missing',
    defaultMessage: 'First Name must be entered.',
  },
  firstNameTooLong: {
    id: 'Signup_LabelFirstName_TooLong',
    defaultMessage: 'First Name must be 25 or fewer characters.',
  },
  surnameMissing: {
    id: 'Signup_LabelSurname_Missing',
    defaultMessage: 'Last Name must be entered.',
  },
  surnameTooLong: {
    id: 'Signup_LabelSurname_TooLong',
    defaultMessage: 'Surname must be 30 or fewer characters.',
  },
  emailAddressMissing: {
    id: 'Signup_LabelEmailAddress_Missing',
    defaultMessage: 'Username or email must be entered.',
  },
  emailAddressInvalidNoAtSymbol: {
    id: 'Signup_LabelEmailAddress_InvalidNoAtSymbol',
    defaultMessage:
      "'{email}' is not a valid email address. Valid email addresses must contain the @ character.",
  },
  emailAddressTooShort: {
    id: 'Signup_LabelEmailAddress_TooShort',
    defaultMessage: 'Email must be at least 6 characters.',
  },
  emailAddressTooLong: {
    id: 'Signup_LabelEmailAddress_TooLong',
    defaultMessage: 'Email can be at most 20 characters.',
  },
  emailAdressInUse: {
    id: 'SignUp_LabelEmailAddress_InUse',
    defaultMessage: 'Email address already exists. Please log in or create an account using a different email.'
  },
  usernameMissing: {
    id: 'Signup_LabelUsername_Missing',
    defaultMessage: 'Username must be entered.',
  },
  usernameInvalidTooShort: {
    id: 'Signup_LabelUsername_InvalidTooShort',
    defaultMessage: 'Username must be at least {usernameMinLength} characters.',
  },
  usernameInvalidTooLong: {
    id: 'Signup_LabelUsername_InvalidTooLong',
    defaultMessage: 'Username can be at most {usernameMaxLength} characters.',
  },
  passwordMissing: {
    id: 'Signup_LabelPassword_Missing',
    defaultMessage: 'Password must be entered.',
  },
  passwordInvalidTooShort: {
    id: 'Signup_LabelPassword_InvalidTooShort',
    defaultMessage: 'Password must be at least {passwordMinLength} characters.',
  },
  passwordInvalidTooLong: {
    id: 'Signup_LabelPassword_InvalidTooLong',
    defaultMessage: 'Password can be at most {passwordMaxLength} characters.',
  },
  passwordInvalidNoNumbersOrSpecialCharacters: {
    id: 'Signup_LabelPassword_InvalidNoNumbersOrSpecialCharacters',
    defaultMessage: 'Password must contain at least 1 number(s) and/or special characters.',
  },
  secretQuestionTypeMissing: {
    id: 'Signup_LabelSecretQuestionType_Missing',
    defaultMessage: 'You must select a security question.',
  },
  secretQuestionAnswerMissing: {
    id: 'Signup_LabelSecretQuestionAnswer_Missing',
    defaultMessage: 'You must provide an answer for the selected security question.',
  },
  termsAndConditionsAcceptedMissing: {
    id: 'Signup_LabelTermsAndConditionsAccepted_Missing',
    defaultMessage: 'You must accept the terms and conditions to proceed.',
  },
  secretQuestion1: {
    id: 'Signup_LabelSecretQuestion_1',
    defaultMessage: "Mother's maiden name",
  },
  ariaLabelPanelDescription: {
    id: 'Signup_AriaLabelPanelDescription',
    defaultMessage: 'Panel Description',
  },
  loginLinkLabel: {
    id: 'Signup_LoginLinkLabel',
    defaultMessage: 'Login',
  },
  showPasswordText: {
    id: 'Signup_ShowPasswordText',
    defaultMessage: 'Show password',
  },
  hidePasswordText: {
    id: 'Signup_HidePasswordText',
    defaultMessage: 'Hide password',
  },
  usernameAlreadyInUseErrorText: {
    id: 'Signup_UsernameAlreadyInUseErrorText',
    defaultMessage:
      'The username entered has already been assigned to another User. Please enter a different name.',
  },
  homeButton: {
    id: 'Login_HomeButton',
    defaultMessage: 'Home',
  },
  mandatoryFieldsAlertText: {
    id: 'Signup_MandatoryFieldsAlertText',
    defaultMessage: 'Complete all fields',
  },
  signupSuccessToastText: {
    defaultMessage: 'You have successfully signed up',
    id: 'SignupSuccessToast_text',
  },
  passwordDontMatch: {
    id: 'Signup_LabelPassword_DontMatch',
    defaultMessage: 'Confirm Password must match new password.',
  },
});
