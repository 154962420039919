/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2018. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RESTService } from '@spm/core';
import { AppSpinner } from '@spm/core-ui';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Authentication,
  ProfileActions,
  ProfileSelectors,
  ProfileModel,
  UserActions,
  UserSelectors,
  UserModels,
} from '@spm/universal-access';
import { FormsSelectors } from '@spm/intelligent-evidence-gathering';
import PATHS from '@spm/universal-access-ui/src/router/Paths';
import ApplicationHeaderComponent from './components/ApplicationHeaderComponent';
import { ID } from './constants';
import featureToggles from '@spm/universal-access-ui/src/features/featureToggles';

;

/**
 * ApplicationHeaderContainer initiates the rendering of the ApplicationHeader
 * page. This component holds state for the ApplicationHeader components.
 *
 * @class ApplicationHeaderContainer
 * @extends {Component}
 */
export class ApplicationHeaderContainer extends Component {
  /**
   * See https://reactjs.org/docs/react-component.html#componentdidmount
   *
   * @memberof ApplicationHeaderContainer
   */
  componentDidMount() {
    // Conside refactoring/deprecating profile REST API and putting it
    // under user API. This would obviously impact props here..
    const { user, userSystemData, loadProfile, loadUser } = this.props;
    if (
      Authentication.userTypeIs([
        Authentication.USER_TYPES.STANDARD,
        Authentication.USER_TYPES.LINKED,
      ])
    ) {
      if (!user) {
        loadProfile();
      }
      if (!userSystemData) {
        loadUser();
      }
    }
  }

  /**
   * See https://reactjs.org/docs/react-component.html#componentwillunmount
   *
   * @memberof ApplicationHeaderContainer
   */
  componentWillUnmount() {
    const { profileError, resetError, userError, resetUserError } = this.props;
    // reset any errors in the store back to null when un-mounting
    if (profileError) {
      resetError();
    }
    if (userError) {
      resetUserError();
    }
  }

  /**
   * Validate if the user is a linked user.
   *
   * @memberof ApplicationHeaderContainer
   */
  isALinkedUser = () => {
    if (Authentication.userTypeIs([Authentication.USER_TYPES.LINKED])) {
      return true;
    }
    return false;
  };

  /**
   * Renders the Application Header page using {@link ApplicationHeaderComponent} or an
   * {@link Alert} if there was a problem loading the data.
   * @returns {@link ApplicationHeaderComponent} or {@link AppSpinner}
   * @memberof ApplicationHeaderComponent
   */
  render() {
    const {
      location,
      user,
      profileError,
      isFetchingProfile,
      isBaseFormIegRunning,
      userSystemData,
    } = this.props;
    let userCanAppeal = false;

    if (userSystemData) {
      // shape of userSystemData will be validated already so safe to get
      // get canAppeal directly
      userCanAppeal = userSystemData.permissions.canAppeal;
    }
    RESTService.handleAPIFailure(profileError);

    // while application form or submission form.
    const isApplicationOrSubmssionForm =
      /\/apply\/-?\d+/.test(this.props.location.pathname) &&
      !/confirm/.test(this.props.location.pathname);

    const mainContentId = ID.MAIN_CONTENT_ID;

    // No Header should be rendered when in:
    // 1-Login or Sign Up page
    // 2-Application or Form Submission
    // 3-IEG Form using BaseFormContainer
    if (
      isBaseFormIegRunning ||
      location.pathname === PATHS.SIGNUP ||
      location.pathname === PATHS.LOGIN ||
      location.pathname === PATHS.IEGVERIFICATIONS.DETAILS ||
      location.pathname === PATHS.IEGVERIFICATIONS.ROOT ||
      isApplicationOrSubmssionForm
    ) {
      return null;
    }

    return !Authentication.userTypeIs([
      Authentication.USER_TYPES.LINKED,
      Authentication.USER_TYPES.STANDARD,
    ]) || !isFetchingProfile ? (
      <ApplicationHeaderComponent
        currentLocation={location.pathname}
        isALinkedUser={this.isALinkedUser}
        isAppealsEnabled={featureToggles.appeals.isEnabled()}
        isUserCanAppeal={userCanAppeal}
        mainContentId={mainContentId}
        profile={user}
      />
    ) : (
      <AppSpinner small />
    );
  }
}

/**
 * The ApplicationHeaderContainer properties.
 *
 * See https://reactjs.org/docs/react-component.html#props
 *
 * @memberof ApplicationHeaderContainer
 */
ApplicationHeaderContainer.propTypes = {
  isBaseFormIegRunning: PropTypes.string,
  isFetchingProfile: PropTypes.bool,
  loadProfile: PropTypes.func.isRequired,
  loadUser: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  profileError: PropTypes.object,
  resetError: PropTypes.func.isRequired,
  resetUserError: PropTypes.func.isRequired,
  user: PropTypes.instanceOf(ProfileModel),
  userError: PropTypes.object,
  userSystemData: PropTypes.instanceOf(UserModels.UAUserInformation),
};

/**
 * The ApplicationHeaderContainer properties.
 *
 * See https://reactjs.org/docs/react-component.html#defaultprops
 *
 * @memberof ApplicationHeaderContainer
 */
ApplicationHeaderContainer.defaultProps = {
  isBaseFormIegRunning: null,
  isFetchingProfile: false,
  profileError: null,
  user: undefined,
  userError: null,
  userSystemData: undefined,
};

/**
 * Retrieves data from the Redux store.
 *
 * @param state  the redux store state
 * @memberof ApplicationHeaderContainer
 */
const mapStateToProps = state => ({
  user: ProfileSelectors.getProfile(state),
  userSystemData: UserSelectors.fetchUser(state),
  profileError: ProfileSelectors.profileError(state),
  isFetchingProfile: ProfileSelectors.isFetchingProfile(state),
  isBaseFormIegRunning: FormsSelectors.getFormId(state),
});

/**
 * Retrieve data from related rest APIs and updates the Redux store.
 *
 * @export
 * @param {function} dispatch the dispatch function
 * @returns {Object} the mappings.
 * @memberof ApplicationHeaderContainer
 */
export const mapDispatchToProps = dispatch => ({
  loadProfile: () => ProfileActions.getUserProfile(dispatch),
  loadUser: () => UserActions.fetchUser(dispatch),
  resetError: () => ProfileActions.resetError(dispatch),
  resetUserError: () => UserActions.resetFetchUserError(dispatch),
});

/**
 * Controls the rendering of the Application Header.
 *
 * The application header is the banner at the top of the application that
 * includes the title of the application and links such as login, signup or
 * the user menus for logged in users.
 */
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ApplicationHeaderContainer));
