/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
import { combineReducers } from 'redux';
import { ReduxUtils } from '@spm/core';
import { POST_MO_SECRET_QUESTION_PASSWORD_RESET, FETCH_MO_USERNAME_EXISTS } from './actionTypes';

/**
 * Updates the details in the 'ForgotPasswordModule' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postMoSecretQuestionPasswordResetReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, POST_MO_SECRET_QUESTION_PASSWORD_RESET);

/**
 * Updates the indicator of creation of details in the 'ForgotPasswordModule' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostMoSecretQuestionPasswordResetReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, POST_MO_SECRET_QUESTION_PASSWORD_RESET);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postMoSecretQuestionPasswordResetErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, POST_MO_SECRET_QUESTION_PASSWORD_RESET);

/**
 * Updates the details in the 'ForgotPasswordModule' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const fetchMoUsernameExistsReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, FETCH_MO_USERNAME_EXISTS);

/**
 * Updates the indicator of creation of details in the 'ForgotPasswordModule' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingFetchMoUsernameExistsReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, FETCH_MO_USERNAME_EXISTS);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const fetchMoUsernameExistsErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, FETCH_MO_USERNAME_EXISTS);

const reducers = combineReducers({
  postMoSecretQuestionPasswordReset: postMoSecretQuestionPasswordResetReducer,

  isFetchingPostMoSecretQuestionPasswordReset: isFetchingPostMoSecretQuestionPasswordResetReducer,
  postMoSecretQuestionPasswordResetError: postMoSecretQuestionPasswordResetErrorReducer,

  fetchMoUsernameExists: fetchMoUsernameExistsReducer,

  isFetchingFetchMoUsernameExists: isFetchingFetchMoUsernameExistsReducer,
  fetchMoUsernameExistsError: fetchMoUsernameExistsErrorReducer,
});

export default { reducers };
