/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2019. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
/*
 * Routes Messages
 *
 * This contains all the text Routes to be displayed on the window's title.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  sampleApplication: {
    id: 'sampleApplication',
    defaultMessage: 'Sample of Apply for Benefits',
  },
  sampleApplicationOverview: {
    id: 'sampleApplicationOverview',
    defaultMessage: 'Sample of Apply for Benefits Overview',
  },
  sampleApplicationForm: {
    id: 'sampleApplicationForm',
    defaultMessage: 'Sample of Apply for Benefits Form',
  },
  sampleApplicationConfirmation: {
    id: 'sampleApplicationConfirmation',
    defaultMessage: 'Sample of Apply for Benefits Confirmation',
  },
  signUp: {
    id: 'signUp',
    defaultMessage: 'The Sign Up Page',
  },
  forgotPasswordForm: {
    id: 'forgotPasswordForm',
    defaultMessage: 'Forgot Password Form',
  },
  remoteIDProofingForm: {
    id: 'remoteIDProofingForm',
    defaultMessage: 'Remote ID Proofing Form',
  },
  MODisclaimer: {
    id: 'MODisclaimer',
    defaultMessage: 'Disclaimer',
  },
  MOHealthNet: {
    id: 'MOHealthNet',
    defaultMessage: 'HealthNet',
  },
  MOOrganisationTitle: {
    id: 'MOOrganisationTitle',
    defaultMessage: 'Social Services'
  },
  submissionFormConfirmation: {
    id: 'Routes_submissionFormConfirmation',
    defaultMessage: 'Submission form confirmation',
  },
  headerYourBenefitsLabel: {
    id: 'Header_YourBenefitsLabel',
    defaultMessage: 'Your benefits',
  },
  headerTermsAndConditions:{
    id: 'Header_TermsAndConditionsLabel',
    defaultMessage: 'Terms And Conditions',
  }
});
