/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

/**
 * Models the NewMOEmailAddress
 *
 * @class NewMOEmailAddress
 */
class NewMOEmailAddress {
  /**
   * Creates an instance of NewMOEmailAddress.
   * @param {*} json
   * @memberof NewMOEmailAddress
   */
  constructor(json = null) {
    if (json) {
      // string
      this.emailAddress = json.emailAddress;

      // string
      this.evidenceID = json.evidenceID;

      // boolean
      this.deleteEmailAddress = json.deleteEmailAddress;
    }
  }
}

/**
 * Models the CodeTableType
 *
 * @class CodeTableType
 */
class CodeTableType {
  /**
   * Creates an instance of CodeTableType.
   * @param {*} json
   * @memberof CodeTableType
   */
  constructor(json = null) {
    if (json) {
      // string
      this.tableName = json.tableName;

      // string
      this.value = json.value;

      // string
      this.description = json.description;

      // string
      this.parentCodeTable = json.parentCodeTable;
    }
  }
}

/**
 * Models the NewMOPhoneNumber
 *
 * @class NewMOPhoneNumber
 */
class NewMOPhoneNumber {
  /**
   * Creates an instance of NewMOPhoneNumber.
   * @param {*} json
   * @memberof NewMOPhoneNumber
   */
  constructor(json = null) {
    if (json) {
      // string
      this.phoneNumber = json.phoneNumber;

      // string
      this.evidenceID = json.evidenceID;

      // string
      this.phoneAreaCode = json.phoneAreaCode;

      // string
      this.phoneExtension = json.phoneExtension;

      // object - CodeTableType
      if (json.phoneType) {
        this.phoneType = new CodeTableType(json.phoneType);
      }

      // boolean
      this.deletePhoneNumber = json.deletePhoneNumber;
    }
  }
}

/**
 * Models the MOEmailAddress
 *
 * @class MOEmailAddress
 */
class MOEmailAddress {
  /**
   * Creates an instance of MOEmailAddress.
   * @param {*} json
   * @memberof MOEmailAddress
   */
  constructor(json = null) {
    if (json) {
      // string
      this.emailAddress = json.emailAddress;

      // string
      this.evidenceID = json.evidenceID;

      // boolean
      this.deleteEmailAddress = json.deleteEmailAddress;
    }
  }
}

/**
 * Models the MOLinkedUser
 *
 * @class MOLinkedUser
 */
class MOLinkedUser {
  /**
   * Creates an instance of MOLinkedUser.
   * @param {*} json
   * @memberof MOLinkedUser
   */
  constructor(json = null) {
    if (json) {
      // boolean
      this.isLinkedUser = json.isLinkedUser;
    }
  }
}

/**
 * Models the MOMailingAddress
 *
 * @class MOMailingAddress
 */
class MOMailingAddress {
  /**
   * Creates an instance of MOMailingAddress.
   * @param {*} json
   * @memberof MOMailingAddress
   */
  constructor(json = null) {
    if (json) {
      // string
      this.mailingAddress = json.mailingAddress;

      // string
      this.evidenceID = json.evidenceID;

      // string
      this.addressLine1 = json.addressLine1;

      // string
      this.addressLine2 = json.addressLine2;

      // string
      this.city = json.city;

      // string
      this.zipCodeFiveDigits = json.zipCodeFiveDigits;

      // object - CodeTableType
      if (json.state) {
        this.state = new CodeTableType(json.state);
      }

      // string
      this.concernRoleID = json.concernRoleID;

      // string
      this.addressID = json.addressID;

      // object - CodeTableType
      if (json.evidenceType) {
        this.evidenceType = new CodeTableType(json.evidenceType);
      }

      // object - CodeTableType
      if (json.usCounty) {
        this.usCounty = new CodeTableType(json.usCounty);
      }

      // string
      this.zipCodeFourDigits = json.zipCodeFourDigits;

      // string
      this.addressData = json.addressData;

      // boolean
      this.isSuccessful = json.isSuccessful;

      // boolean
      this.isValidated = json.isValidated;
    }
  }
}

/**
 * Models the MOPhoneNumber
 *
 * @class MOPhoneNumber
 */
class MOPhoneNumber {
  /**
   * Creates an instance of MOPhoneNumber.
   * @param {*} json
   * @memberof MOPhoneNumber
   */
  constructor(json = null) {
    if (json) {
      // string
      this.phoneNumber = json.phoneNumber;

      // string
      this.number = json.number;

      // string
      this.evidenceID = json.evidenceID;

      // string
      this.phoneAreaCode = json.phoneAreaCode;

      // string
      this.phoneExtension = json.phoneExtension;

      // object - CodeTableType
      if (json.phoneType) {
        this.phoneType = new CodeTableType(json.phoneType);
      }

      // boolean
      this.deletePhoneNumber = json.deletePhoneNumber;
    }
  }
}

/**
 * Models the MOPreferredMethodOfCommunication
 *
 * @class MOPreferredMethodOfCommunication
 */
class MOPreferredMethodOfCommunication {
  /**
   * Creates an instance of MOPreferredMethodOfCommunication.
   * @param {*} json
   * @memberof MOPreferredMethodOfCommunication
   */
  constructor(json = null) {
    if (json) {
      // object - CodeTableType
      if (json.preferredMethodOfCommunication) {
        this.preferredMethodOfCommunication = new CodeTableType(
          json.preferredMethodOfCommunication
        );
      }

      // string
      this.evidenceID = json.evidenceID;
    }
  }
}

/**
 * Models the MOResidentialAddress
 *
 * @class MOResidentialAddress
 */
class MOResidentialAddress {
  /**
   * Creates an instance of MOResidentialAddress.
   * @param {*} json
   * @memberof MOResidentialAddress
   */
  constructor(json = null) {
    if (json) {
      // string
      this.residentialAddress = json.residentialAddress;
    }
  }
}

/**
 * Models the MOContactInformation
 *
 * @class MOContactInformation
 */
class MOContactInformation {
  /**
   * Creates an instance of MOContactInformation.
   * @param {*} json
   * @memberof MOContactInformation
   */
  constructor(json = null) {
    if (json) {
      // array - MOEmailAddress
      if (json.emailAddressDtlsList) {
        this.emailAddressDtlsList = json.emailAddressDtlsList.map(
          object => new MOEmailAddress(object)
        );
      } else if (json.constructor === Array) {
        this.emailAddressDtlsList = json.map(object => new MOEmailAddress(object));
      }

      // object - MOLinkedUser
      if (json.linkedUser) {
        this.linkedUser = new MOLinkedUser(json.linkedUser);
      }

      // array - MOMailingAddress
      if (json.mailingAddressDtlsList) {
        this.mailingAddressDtlsList = json.mailingAddressDtlsList.map(
          object => new MOMailingAddress(object)
        );
      } else if (json.constructor === Array) {
        this.mailingAddressDtlsList = json.map(object => new MOMailingAddress(object));
      }

      // array - MOPhoneNumber
      if (json.phoneNumberDtlsList) {
        this.phoneNumberDtlsList = json.phoneNumberDtlsList.map(
          object => new MOPhoneNumber(object)
        );
      } else if (json.constructor === Array) {
        this.phoneNumberDtlsList = json.map(object => new MOPhoneNumber(object));
      }

      // array - MOPreferredMethodOfCommunication
      if (json.preferredMethodOfCommunicationDtlsList) {
        this.preferredMethodOfCommunicationDtlsList =
          json.preferredMethodOfCommunicationDtlsList.map(
            object => new MOPreferredMethodOfCommunication(object)
          );
      } else if (json.constructor === Array) {
        this.preferredMethodOfCommunicationDtlsList = json.map(
          object => new MOPreferredMethodOfCommunication(object)
        );
      }

      // array - MOResidentialAddress
      if (json.residentialAddressDtlsList) {
        this.residentialAddressDtlsList = json.residentialAddressDtlsList.map(
          object => new MOResidentialAddress(object)
        );
      } else if (json.constructor === Array) {
        this.residentialAddressDtlsList = json.map(object => new MOResidentialAddress(object));
      }
    }
  }
}

export default {
  NewMOEmailAddress,

  CodeTableType,

  NewMOPhoneNumber,

  MOEmailAddress,

  MOLinkedUser,

  MOMailingAddress,

  MOPhoneNumber,

  MOPreferredMethodOfCommunication,

  MOResidentialAddress,

  MOContactInformation,
};
