/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2019. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
const Paths = {
  ACCOUNT: '/account',
  HOME: '/',
  APPLICATION_OVERVIEW: '/application-overview',
  APPLY: '/apply',
  BENEFIT_SELECTION: '/benefit-selection',
  BENEFITS: '/benefits',
  PROFILE: '/profile',
  SIGNUP: '/signup',
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot-password',
  PAYMENTS: {
    ROOT: '/payments',
    DETAILS: '/payments/details',
  },
  DISCLAIMER: '/mo-disclaimer',
  CASE_EXISTS: '/mo-case-exists',
  TERMS_AND_CONDITIONS: '/terms-and-conditions',
  ELIGIBILITY: {
    ROOT: '/eligibility',
    BENEFIT_SELECTION: '/eligibility/benefit-selection',
    FORM: '/eligibility/form/new',
    RESUME: '/eligibility/form',
    FILTER_FORM: '/eligibility/filter-form/new',
    FILTER_FORM_RESUME: '/eligibility/filter-form',
  },
  VERIFICATIONS: {
    ROOT: '/verifications',
    DETAILS: '/verifications/details',
  },
  REMOTE_ID_PROOFING: '/remoteIDProofing',
  SAMPLE_APPLICATION: {
    ROOT: '/sample-application',
    OVERVIEW: '/sample-application/overview',
    FORM: '/sample-application/form',
    CONFIRMATION: '/sample-application/confirmation',
  },
};

/**
 * Provides access the the URLs that are mapped to each page via the route configuration.
 */
export default Paths;
