/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
/*
 *
 * Action types for SampleModule.
 *
 */

import { ReduxUtils } from '@spm/core';

const FETCH_ONLINE_CATEGORIES = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `SAMPLEMODULE/FETCH_ONLINE_CATEGORIES`
);

const CREATE_APPLICATION_FORM = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `SAMPLEMODULE/CREATE_APPLICATION_FORM`
);

const GET_USERS_BY_USER_NAME = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `SAMPLEMODULE/GET_USERS_BY_USER_NAME`
);

const SET_SELECTED_APPLICATION_TYPE = ReduxUtils.generateActionType(
  'ua-library',
  `SAMPLEMODULE/SET_SELECTED_APPLICATION_TYPE`,
  'SET'
);

export {
  FETCH_ONLINE_CATEGORIES,
  CREATE_APPLICATION_FORM,
  GET_USERS_BY_USER_NAME,
  SET_SELECTED_APPLICATION_TYPE,
};
