/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2018,2019. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
const ID = {
  APPLICATION_HEADER_SIGNUP_LINK_ID: 'application-header-signup-link',
  APPLICATION_HEADER_LOGIN_LINK_ID: 'application-header-login-link',
  PROFILE_MENU_ID: 'overflow-profile-menu',
  PROFILE_MENU_PROFILE_BUTTON_ID: 'overflow-profile-button',
  PROFILE_MENU_LOGOUT_BUTTON_ID: 'overflow-logout-button',
  APPLICATION_HEADER_COMPONENT_ID: 'application-header-component',
  APPLICATION_HEADER_HOME_LINK_ID: 'application-header-home-link',
  MAIN_CONTENT_ID: 'maincontent',
};

const NAVIGATION_HEADER_TABS = {
  FIND_HELP: { NAME: 'FIND_HELP', ID: 'navigation-find-help' },
  YOUR_ACCOUNT: { NAME: 'YOUR_ACCOUNT', ID: 'navigation-your-account' },
  FAQS: { NAME: 'FAQS', ID: 'navigation-faqs' },
  CONTACT_US: { NAME: 'CONTACT_US', ID: 'navigation-contact-us' },
  HOME: { NAME: 'HOME', ID: 'navigation-home' },
  BENEFITS: { NAME: 'BENEFITS', ID: 'navigation-benefits' },
  DASHBOARD: { NAME: 'DASHBOARD', ID: 'navigation-dashboard' },
  YOUR_BENEFITS: { NAME: 'YOUR_BENEFITS', ID: 'navigation-your-benefits' },
  PROFILE: { NAME: 'PROFILE', ID: 'navigation-profile' },
  APPEALS: { NAME: 'APPEALS', ID: 'navigation-appeals' },
  NOTICES: { NAME: 'NOTICES', ID: 'navigation-notices' },
  VERIFICATIONS: { NAME: 'VERIFICATIONS', ID: 'navigation-verifications' },
};

/* eslint-disable import/prefer-default-export */
export { ID, NAVIGATION_HEADER_TABS };
