/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2018. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  ariaSection: {
    id: 'Login_AriaSection',
    defaultMessage: 'Log in Section',
  },
  title: {
    id: 'Login_LabelTitle',
    defaultMessage: 'Log in',
  },
  subtitle: {
    id: 'Login_LabelSubtitle',
    defaultMessage: 'To save your progress log in below',
  },
  description: {
    id: 'Login_Description',
    defaultMessage: 'Log in to view your account, see your next payment and much more.',
  },
  agencyTitle: {
    id: 'Login_AgencyTitle',
    defaultMessage: 'Log in to your myDSS account',
  },
  homeButton: {
    id: 'Login_HomeButton',
    defaultMessage: 'Home',
  },
  username: {
    id: 'Login_LabelUsername',
    defaultMessage: 'Username',
  },
  password: {
    id: 'Login_LabelPassword',
    defaultMessage: 'Password',
  },
  showPassword: {
    id: 'Login_LabelShowPassword',
    defaultMessage: 'Show password',
  },
  submitButton: {
    id: 'Login_LabelSubmitButton',
    defaultMessage: 'Log in',
  },
  forgotPassword: {
    id: 'Login_LabelForgotPassword',
    defaultMessage: 'Forgot password?',
  },
  noAccountMessage: {
    id: 'Login_LabelNoAccountMessage',
    defaultMessage: "Don't have an account? {link}",
  },
  noAccountMessageLink: {
    id: 'Login_LabelNoAccountMessageLink',
    defaultMessage: 'Sign up',
  },
  anonSubmit: {
    id: 'Login_LabelAnonSubmit',
    defaultMessage: 'Send application without account',
  },
  loginFailedMessage: {
    id: 'Login_LabelLoginFailedMessage',
    defaultMessage: 'The username or password is incorrect, please try again.',
  },
  timeout: {
    id: 'Login_LabelTimeout',
    defaultMessage:
      'You were automatically logged out to keep your information secure. Log in to go back to where you left off.',
  },
  passwordChanged: {
    id: 'Login_LabelPasswordChanged',
    defaultMessage: 'Password successfully updated. You can now log in using your new password.',
  },
  signUpButtonLabel: {
    id: 'Login_LabelSignUp',
    defaultMessage: 'Sign Up',
  },
  ariaLabelPanelDescription: {
    id: 'Login_AriaLabelPanelDescription',
    defaultMessage: 'Panel Description',
  },
  altLabelAgencyLogo: {
    id: 'Login_AltLabelAgencyLogo',
    defaultMessage: 'Agency Logo',
  },
  showPasswordText: {
    id: 'Login_ShowPasswordText',
    defaultMessage: 'Show password',
  },
  hidePasswordText: {
    id: 'Login_HidePasswordText',
    defaultMessage: 'Hide password',
  },
  loginSuccessToastText: {
    defaultMessage: "You're now logged in",
    id: 'Signup_Success_Toast_text',
  },
});
