/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2018,2019. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Main } from '@govhhs/govhhs-design-system-react';
import {
  ScrollToTop,
  SessionTimeout,
  ErrorBoundary,
  SSOVerifier,
  Toaster,
  ConnectivityHandler,
  CookiesHandler,
} from '@spm/universal-access-ui';
import ApplicationHeader from './features/ApplicationHeader';
import ApplicationHeaderComponent from './features/ApplicationHeader/components/ApplicationHeaderComponent';
import MOApplicationFooterComponent from './features/MOApplicationFooter/MOApplicationFooterComponent';
import routes from './routes';
import ReduxInit from './redux/ReduxInit';
import IntlInit from './intl/IntlInit';
import CompatibilityInit from './compatibility/CompatibilityInit';
import DOMPurify from 'dompurify';


window.uaapp = {
  envVars: process.env,
};
/**
 * App component entry point.
 */
const App = () => {
  DOMPurify.setConfig({ ADD_ATTR: ['target'] })

  return (
  <ReduxInit>
    <IntlInit>
      <Router basename={process.env.PUBLIC_URL}>
        <ScrollToTop>
          <CompatibilityInit>
            <ErrorBoundary
              footer={<MOApplicationFooterComponent />}
              header={<ApplicationHeaderComponent hasErrorBeenCaught />}
              isHeaderBoundary
            >
              <CookiesHandler
                footer={<MOApplicationFooterComponent />}
                header={
                  <ApplicationHeaderComponent
                    isALinkedUser={() => false}
                    isAppealsEnabled={false}
                    isEmpty={false}
                  />
                }
              >
                <ConnectivityHandler>
                  <SSOVerifier
                    placeholder={
                      <>
                        <ApplicationHeaderComponent
                          isALinkedUser={() => false}
                          isAppealsEnabled={false}
                          isEmpty={false}
                        />
                        <Main
                          className="wds-u-bg--page wds-u-text-word-break"
                          id="maincontent"
                          pushFooter
                        >
                          {routes}
                        </Main>
                      </>
                    }
                  >
                    <ApplicationHeader />
                    <ErrorBoundary>
                      <SessionTimeout />
                      <Toaster />
                      <Main
                        className="wds-u-bg--page wds-u-text-word-break"
                        id="maincontent"
                        pushFooter
                      >
                        {routes}
                      </Main>
                    </ErrorBoundary>
                    <MOApplicationFooterComponent />
                  </SSOVerifier>
                </ConnectivityHandler>
              </CookiesHandler>
            </ErrorBoundary>
          </CompatibilityInit>
        </ScrollToTop>
      </Router>
    </IntlInit>
  </ReduxInit>
  )
};

export default App;
