/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
import { combineReducers } from 'redux';
import { ReduxUtils } from '@spm/core';
import {
  POST_MO_EDIT_EMAIL_ADDRESS,
  POST_MO_CREATE_EMAIL_ADDRESS,
  POST_MO_CREATE_PHONE_NUMBER,
  POST_MO_EDIT_PHONE_NUMBER,
  FETCH_MO_LIST_CONTACT_INFORMATION,
} from './actionTypes';

/**
 * Updates the details in the 'ContactInformationModule' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postMoEditEmailAddressReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, POST_MO_EDIT_EMAIL_ADDRESS);

/**
 * Updates the indicator of creation of details in the 'ContactInformationModule' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostMoEditEmailAddressReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, POST_MO_EDIT_EMAIL_ADDRESS);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postMoEditEmailAddressErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, POST_MO_EDIT_EMAIL_ADDRESS);

/**
 * Updates the details in the 'ContactInformationModule' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postMoCreateEmailAddressReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, POST_MO_CREATE_EMAIL_ADDRESS);

/**
 * Updates the indicator of creation of details in the 'ContactInformationModule' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostMoCreateEmailAddressReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, POST_MO_CREATE_EMAIL_ADDRESS);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postMoCreateEmailAddressErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, POST_MO_CREATE_EMAIL_ADDRESS);

/**
 * Updates the details in the 'ContactInformationModule' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postMoCreatePhoneNumberReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, POST_MO_CREATE_PHONE_NUMBER);

/**
 * Updates the indicator of creation of details in the 'ContactInformationModule' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostMoCreatePhoneNumberReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, POST_MO_CREATE_PHONE_NUMBER);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postMoCreatePhoneNumberErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, POST_MO_CREATE_PHONE_NUMBER);

/**
 * Updates the details in the 'ContactInformationModule' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postMoEditPhoneNumberReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, POST_MO_EDIT_PHONE_NUMBER);

/**
 * Updates the indicator of creation of details in the 'ContactInformationModule' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostMoEditPhoneNumberReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, POST_MO_EDIT_PHONE_NUMBER);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postMoEditPhoneNumberErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, POST_MO_EDIT_PHONE_NUMBER);

/**
 * Updates the details in the 'ContactInformationModule' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const fetchMoListContactInformationReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, FETCH_MO_LIST_CONTACT_INFORMATION);

/**
 * Updates the indicator of creation of details in the 'ContactInformationModule' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingFetchMoListContactInformationReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, FETCH_MO_LIST_CONTACT_INFORMATION);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const fetchMoListContactInformationErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, FETCH_MO_LIST_CONTACT_INFORMATION);

const reducers = combineReducers({
  postMoEditEmailAddress: postMoEditEmailAddressReducer,

  isFetchingPostMoEditEmailAddress: isFetchingPostMoEditEmailAddressReducer,
  postMoEditEmailAddressError: postMoEditEmailAddressErrorReducer,

  postMoCreateEmailAddress: postMoCreateEmailAddressReducer,

  isFetchingPostMoCreateEmailAddress: isFetchingPostMoCreateEmailAddressReducer,
  postMoCreateEmailAddressError: postMoCreateEmailAddressErrorReducer,

  postMoCreatePhoneNumber: postMoCreatePhoneNumberReducer,

  isFetchingPostMoCreatePhoneNumber: isFetchingPostMoCreatePhoneNumberReducer,
  postMoCreatePhoneNumberError: postMoCreatePhoneNumberErrorReducer,

  postMoEditPhoneNumber: postMoEditPhoneNumberReducer,

  isFetchingPostMoEditPhoneNumber: isFetchingPostMoEditPhoneNumberReducer,
  postMoEditPhoneNumberError: postMoEditPhoneNumberErrorReducer,

  fetchMoListContactInformation: fetchMoListContactInformationReducer,

  isFetchingFetchMoListContactInformation: isFetchingFetchMoListContactInformationReducer,
  fetchMoListContactInformationError: fetchMoListContactInformationErrorReducer,
});

export default { reducers };
