/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
/**
 * Contains the selectors that select state from the 'ContactInformationModule' store object.
 * @namespace ContactInformationModuleSelectors
 */
export default class selectors {
  /**
   * @private
   */
  static moduleIdentifier = 'UAContactInformationModule';

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof ContactInformationModuleSelectors
   */
  static postMoEditEmailAddress = state =>
    state[selectors.moduleIdentifier].postMoEditEmailAddress.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof ContactInformationModuleSelectors
   */
  static isFetchingPostMoEditEmailAddress = state =>
    state[selectors.moduleIdentifier].isFetchingPostMoEditEmailAddress.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof ContactInformationModuleSelectors
   */
  static postMoEditEmailAddressError = state =>
    state[selectors.moduleIdentifier].postMoEditEmailAddressError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof ContactInformationModuleSelectors
   */
  static postMoCreateEmailAddress = state =>
    state[selectors.moduleIdentifier].postMoCreateEmailAddress.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof ContactInformationModuleSelectors
   */
  static isFetchingPostMoCreateEmailAddress = state =>
    state[selectors.moduleIdentifier].isFetchingPostMoCreateEmailAddress.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof ContactInformationModuleSelectors
   */
  static postMoCreateEmailAddressError = state =>
    state[selectors.moduleIdentifier].postMoCreateEmailAddressError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof ContactInformationModuleSelectors
   */
  static postMoCreatePhoneNumber = state =>
    state[selectors.moduleIdentifier].postMoCreatePhoneNumber.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof ContactInformationModuleSelectors
   */
  static isFetchingPostMoCreatePhoneNumber = state =>
    state[selectors.moduleIdentifier].isFetchingPostMoCreatePhoneNumber.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof ContactInformationModuleSelectors
   */
  static postMoCreatePhoneNumberError = state =>
    state[selectors.moduleIdentifier].postMoCreatePhoneNumberError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof ContactInformationModuleSelectors
   */
  static postMoEditPhoneNumber = state =>
    state[selectors.moduleIdentifier].postMoEditPhoneNumber.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof ContactInformationModuleSelectors
   */
  static isFetchingPostMoEditPhoneNumber = state =>
    state[selectors.moduleIdentifier].isFetchingPostMoEditPhoneNumber.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof ContactInformationModuleSelectors
   */
  static postMoEditPhoneNumberError = state =>
    state[selectors.moduleIdentifier].postMoEditPhoneNumberError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof ContactInformationModuleSelectors
   */
  static fetchMoListContactInformation = state =>
    state[selectors.moduleIdentifier].fetchMoListContactInformation.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof ContactInformationModuleSelectors
   */
  static isFetchingFetchMoListContactInformation = state =>
    state[selectors.moduleIdentifier].isFetchingFetchMoListContactInformation.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof ContactInformationModuleSelectors
   */
  static fetchMoListContactInformationError = state =>
    state[selectors.moduleIdentifier].fetchMoListContactInformationError.payload;
}
