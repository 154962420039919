/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2018,2019. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  profilePageAriaLabel: {
    id: 'Profile_ProfilePageAriaLabel',
    defaultMessage: 'Profile Page',
  },
  personalInformationAriaLabel: {
    id: 'Profile_PersonalInformationAriaLabel',
    defaultMessage: 'Personal Information Section',
  },
  contactInformationAriaLabel: {
    id: 'Profile_ContactInformationAriaLabel',
    defaultMessage: 'Contact Information Section',
  },
  personalInformationTitle: {
    id: 'Profile_PersonalInformationTitle',
    defaultMessage: 'Profile: {name}',
  },
  personalInformationSubtitle: {
    id: 'Profile_personalInformationSubtitle',
    defaultMessage:
      "Keep your profile up to date and tell us about any changes so that we can review to make sure you're getting the right help.",
  },
  dateOfBirthTitleText: {
    id: 'Profile_DateOfBirthTitleText',
    defaultMessage: 'Date of birth',
  },
  socialSecurityNumberTitleText: {
    id: 'Profile_SocialSecurityNumberTitleText',
    defaultMessage: 'SSN',
  },
  contactInfoTitle: {
    id: 'Profile_ContactInformationTitle',
    defaultMessage: 'Contact Information',
  },
  contactInformationEditPhoneNumberTitle: {
    id: 'Profile_ContactInformationEditPhoneNumberTitle',
    defaultMessage: 'Your Phone Number(s)'
  },
  contactInformationAddressHeading: {
    id: 'Profile_ContactInformationAddressHeading',
    defaultMessage: 'Address',
  },
  contactInformationMailingAddressHeading: {
    id: 'Profile_ContactInformationMailingAddressHeading',
    defaultMessage: 'Mailing address',
  },
  contactInformationPhoneNumberHeading: {
    id: 'Profile_ContactInformationPhoneNumberHeading',
    defaultMessage: 'Primary phone number',
  },
  contactInformationEmailAddressHeading: {
    id: 'Profile_ContactInformationEmailAddressHeading',
    defaultMessage: 'Email address',
  },
  contactInformationNoDataRegisteredText: {
    id: 'Profile_ContactInformationNoDataRegisteredText',
    defaultMessage: 'Data restricted or unavailable',
  },
  contactInformationSameAsAddressText: {
    id: 'Profile_ContactInformationSameAsAddressText',
    defaultMessage: 'Same as contact address',
  },
  contactInformationEmailMissing: {
    id: 'Profile_ContactInformationEmailMissingText',
    defaultMessage: 'Email must be entered',
  },
  contactInformationInvalidEmail: {
    id: 'Profile_ContactInformationInvalidEmailText',
    defaultMessage: 'Not a valid email adress.',
  },
  contactInformationEmailAddressTooLong: {
    id: 'Profile_LabelEmailAddress_TooLong',
    defaultMessage: 'Email can be at most 60 characters.',
  },
  contactInformationPhoneNumberInvalid: {
    id: 'Profile_phoneNumberInvalid',
    defaultMessage: 'Not a valid phone number. Phone number must contain 7 numbers.'
  },
  contactInformationAreaCodeInvalid: {
    id: 'Profile_phoneNumberInvalid',
    defaultMessage: 'Not a valid Area Code. Area Code must contain 3 numbers.'
  },
  contactInformationPhoneTypelMissing: {
    id: 'Profile_ContactInformationEmailPhoneTypeText',
    defaultMessage: 'Type must be selected'
  }
});
