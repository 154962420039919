/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

import { ReduxUtils } from '@spm/core';

import {
  FETCH_MO_USER_VALIDATED,
  POST_MO_VERIFY_USER,
  POST_MO_SECONDARY_VERIFY_USER,
} from './actionTypes';

import {
  fetchMoUserValidatedUtil,
  postMoVerifyUserUtil,
  postMoSecondaryVerifyUserUtil,
} from './utils';

/**
 * Contains the action creator functions that update the RIDPModule slice of the store.
 *
 * @namespace RIDPModuleActions
 */
export default class actions {
  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/mo_user_validated
   *
   * @param {string} username.
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof RIDPModuleActions
   */
  static fetchMoUserValidated = (dispatch, username, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      fetchMoUserValidatedUtil.bind(null, username),

      FETCH_MO_USER_VALIDATED,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to fetchMoUserValidated.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof RIDPModuleActions
   */
  static resetFetchMoUserValidated = (dispatch, data) => {
    const json = {
      type: FETCH_MO_USER_VALIDATED.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to fetchMoUserValidated.
   *
   * @param {function} dispatch
   * @function
   * @memberof RIDPModuleActions
   */
  static resetFetchMoUserValidatedError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, FETCH_MO_USER_VALIDATED.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/mo_verify_user
   *
   * @param {NewMOUserDetails} data object that will be posted to the api
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof RIDPModuleActions
   */
  static postMoVerifyUser = (dispatch, newMOUserDetails, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      postMoVerifyUserUtil.bind(null, newMOUserDetails),
      POST_MO_VERIFY_USER,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postMoVerifyUser.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof RIDPModuleActions
   */
  static resetPostMoVerifyUser = (dispatch, data) => {
    const json = {
      type: POST_MO_VERIFY_USER.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postMoVerifyUser.
   *
   * @param {function} dispatch
   * @function
   * @memberof RIDPModuleActions
   */
  static resetPostMoVerifyUserError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, POST_MO_VERIFY_USER.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/mo_secondary_verify_user
   *
   * @param {NewMOSecondaryUserDetails} data object that will be posted to the api
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof RIDPModuleActions
   */
  static postMoSecondaryVerifyUser = (dispatch, newMOSecondaryUserDetails, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      postMoSecondaryVerifyUserUtil.bind(null, newMOSecondaryUserDetails),
      POST_MO_SECONDARY_VERIFY_USER,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postMoSecondaryVerifyUser.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof RIDPModuleActions
   */
  static resetPostMoSecondaryVerifyUser = (dispatch, data) => {
    const json = {
      type: POST_MO_SECONDARY_VERIFY_USER.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postMoSecondaryVerifyUser.
   *
   * @param {function} dispatch
   * @function
   * @memberof RIDPModuleActions
   */
  static resetPostMoSecondaryVerifyUserError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, POST_MO_SECONDARY_VERIFY_USER.failureAction);
  };
}
