/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

import { RESTService, ReduxUtils } from '@spm/core';
import models from './models';

/**
 * Gets UAOnlineCategoryList from api:
 * /v1/ua/online_categories
 *
 * @param {any} callback function to call once this function has finished
 */
const fetchOnlineCategoriesUtil = callback => {
  ReduxUtils.getModelDataFromRestAPI(
    callback,
    `v1/ua/online_categories`,
    models.UAOnlineCategoryList
  );
};

/**
 * Post request to path:
 * /v1/ua/application_form
 *
 * @param {any} data
 * @param {any} callback function to call once this function has finished
 */
const createApplicationFormUtil = (data = {}, callback) => {
  const url = `${process.env.REACT_APP_REST_URL}/v1/ua/application_form`;

  RESTService.post(url, data, (success, response) => {
    if (success) {
      const model = new models.NewUAApplicationFormIdentifierResponse(response);
      callback(success, model);
    } else {
      callback(success, response);
    }
  });
};

/**
 * Gets User from api:
 * /v1/users/{user_name}
 *
 * @param {string} user_name.
 * @param {any} callback function to call once this function has finished
 */
const getUsersByUserNameUtil = (user_name, callback) => {
  ReduxUtils.getModelDataFromRestAPI(callback, `v1/users/${user_name}`, models.User);
};

export { fetchOnlineCategoriesUtil, createApplicationFormUtil, getUsersByUserNameUtil };
