/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2019. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

import React from 'react';
import { Switch } from 'react-router-dom';
import { Routes as UARoutes, AuthenticatedRoute, TitledRoute, AppSpinner } from '@spm/universal-access-ui';
import { LoadingPage } from '@spm/core-ui';
import { Authentication } from '@spm/universal-access';
import applyForBenfitsTranslations from '@spm/universal-access-ui/src/features/ApplyForBenefits/components/ApplyForBenefitsComponentMessages';
import routeTranslations from '@spm/universal-access-ui/src/router/RoutesMessages';
import Loadable from 'react-loadable';
import routeTitles from './routesMessages';
import paths from './paths';
import signupTranslations from './features/Signup/components/SignupComponentMessages';
import loginTranslations from './features/Login/components/LoginComponentMessages';
import profileTranslations from './features/Profile/components/ProfileComponentMessages';
import applicationHeaderTranslations from './features/ApplicationHeader/components/ApplicationHeaderMessages'
/*  This routes.jsx file can be customized to add new components or
    Redirect the existing components to new routes:

   Sample:
     <Switch>
       <Route path="/ourPayments" component={OurPayments} />
       <Redirect from="/payments" to="/ourPayments" />
       <UARoutes />
     </Switch>
*/

/**
 * Default loading component to display while loading other components.
 */
const loadingComponent = () => <AppSpinner />;

const SampleApplication = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "SampleApplication" */ './features/sampleApplication/SampleApplicationComponent'
    ),
  loading: loadingComponent,
});
const SampleApplicationForm = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "SampleApplicationForm" */ './features/sampleApplication/form/SampleApplicationFormComponent'
    ),
  loading: loadingComponent,
});
const SampleApplicationOverview = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "SampleApplicationOverview" */ './features/sampleApplication/overview/SampleApplicationOverviewComponent'
    ),
  loading: loadingComponent,
});
const SampleApplicationConfirmation = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "SampleApplicationConfirmation" */ './features/sampleApplication/confirmation/SampleApplicationConfirmation'
    ),
  loading: loadingComponent,
});
const ForgotPassword = Loadable({
  loader: () => import('./features/ForgotPassword'),
  loading: LoadingPage,
})

const AccountHome = Loadable({
  loader: () => import('./features/AccountHome'),
  loading: LoadingPage,
});

const ApplyForBenefits = Loadable({
  loader: () => import('./features/ApplyForBenefits'),
  loading: LoadingPage,
});

const BenefitSelection = Loadable({
  loader: () => import('./features/BenefitSelection'),
  loading: LoadingPage,
});

const SubmissionFormConfirmation = Loadable({
  loader: () =>
    import(
       './features/Forms/SubmissionForm/SubmissionFormConfirmationComponent'
    ),
  loading: LoadingPage,
});

const Profile = Loadable({
  loader: () =>
    import(
      './features/Profile'
    ),
  loading: loadingComponent,
});

const YourBenefits = Loadable({
  loader: () =>
    import(
      './features/YourBenefits'
    ),
  loading: loadingComponent,
});


const Disclaimer = Loadable({
  loader: () =>
    import(
      './features/MODisclaimer/MODisclaimer'
    ),
  loading: loadingComponent,
});

const CaseExists = Loadable({
  loader: () =>
    import(
      './features/MODisclaimer/MOCaseExists'
    ),
  loading: loadingComponent,
});
const TermsAndConditions = Loadable({
  loader: () =>
    import(
      './features/MOTermsAndConditions/MOTermsAndConditions'
    ),
  loading: loadingComponent,
});
const OrganisationComponent = Loadable({
  loader: () =>
    import(
      './features/Organisation'
    ),
  loading: loadingComponent,
});

const ApplicationOverviewComponent = Loadable({
  loader: () =>
    import(
      './features/ApplicationOverview'
    ),
  loading: loadingComponent,
});

const Signup = Loadable({
  loader: () =>
    import(
      './features/Signup'
    ),
  loading: loadingComponent,
});
const Login = Loadable({
  loader: () =>
    import(
      './features/Login'
    ),
  loading: loadingComponent,
});

const MORemoteIDProofing = Loadable({
  loader: () => import('./features/MORemoteIDProofing'),
  loading: loadingComponent,
});

export default (
  <Switch>
    {/* Sample Routes - Example features. Not intended to run in production. */}
    <TitledRoute
      component={SampleApplicationForm}
      exact
      path={`${paths.SAMPLE_APPLICATION.FORM}/:formId`}
      title={routeTitles.sampleApplicationForm}
    />
    <TitledRoute
      component={SampleApplicationConfirmation}
      exact
      path={`${paths.SAMPLE_APPLICATION.CONFIRMATION}/:formId`}
      title={routeTitles.sampleApplicationConfirmation}
    />
    <TitledRoute
      component={SampleApplicationOverview}
      exact
      path={paths.SAMPLE_APPLICATION.OVERVIEW}
      title={routeTitles.sampleApplicationOverview}
    />
    <TitledRoute
      component={SampleApplication}
      exact
      path={paths.SAMPLE_APPLICATION.ROOT}
      title={routeTitles.sampleApplication}
    />
    {/* Routes for production */}  
    <TitledRoute
      component={ApplyForBenefits}
      exact
      path={paths.APPLY}
      title={applyForBenfitsTranslations.applyForBenefitsPageTitle}
    />   
    <TitledRoute
      component={Signup}
      exact
      path={paths.SIGNUP}
      title={signupTranslations.title}
    />
    <TitledRoute
      component={Login}
      exact
      path={paths.LOGIN}
      title={loginTranslations.title}
    />
    <TitledRoute
      component={ForgotPassword}
      exact
      path={paths.FORGOT_PASSWORD}
      title={routeTitles.forgotPasswordForm}
    />
    <TitledRoute
          component={OrganisationComponent}
          exact
          path={paths.HOME}
          title={routeTitles.MOOrganisationTitle}
    />
    <TitledRoute
          component={SubmissionFormConfirmation}
          exact
          path={`${paths.APPLY}/:applicationFormId/confirm`}
          title={routeTranslations.submissionFormConfirmation}
    />
    <TitledRoute
          component={TermsAndConditions}
          exact
          path={paths.TERMS_AND_CONDITIONS}
          title={routeTitles.headerTermsAndConditions}
    />
    {/* START: Apply for benefits protected routing */}
    <TitledRoute
      component={BenefitSelection}
      exact
      path={paths.BENEFIT_SELECTION}
      title={routeTranslations.benefitSelection}
    />
    {/* END: Apply for benefits protected routing */}
    {/*RIDP Protected Routing */}
    <AuthenticatedRoute
          authUserTypes={[Authentication.USER_TYPES.STANDARD, Authentication.USER_TYPES.LINKED]}
          component={MORemoteIDProofing}
          exact
          path={paths.REMOTE_ID_PROOFING}
          title={routeTitles.remoteIDProofingForm}
    />
    {/*Profile Page Protected Routing*/}
    <AuthenticatedRoute
          authUserTypes={[Authentication.USER_TYPES.LINKED]}
          component={Profile}
          exact
          path={paths.PROFILE}
          title={profileTranslations.profilePageAriaLabel}
    />
    <AuthenticatedRoute
          authUserTypes={[Authentication.USER_TYPES.STANDARD, Authentication.USER_TYPES.LINKED]}
          component={AccountHome}
          exact
          path={paths.ACCOUNT}
          title={applicationHeaderTranslations.headerDashboardLabel}
    />
    <AuthenticatedRoute
          authUserTypes={[Authentication.USER_TYPES.STANDARD, Authentication.USER_TYPES.LINKED]}
          component={ApplicationOverviewComponent}
          exact
          path={paths.APPLICATION_OVERVIEW}
          title={applicationHeaderTranslations.headerDashboardLabel}
    />
     <TitledRoute
          component={ApplicationOverviewComponent}
          exact
          path={`${paths.APPLICATION_OVERVIEW}/:intakeApplicationTypeId/:programIds`}
          title={applicationHeaderTranslations.headerDashboardLabel}
        />
    <AuthenticatedRoute
          authUserTypes={[Authentication.USER_TYPES.STANDARD, Authentication.USER_TYPES.LINKED]}
          component={MORemoteIDProofing}
          exact
          path={paths.REMOTE_ID_PROOFING}
          title={routeTitles.remoteIDProofingForm}
    />
    <AuthenticatedRoute
          authUserTypes={[Authentication.USER_TYPES.STANDARD, Authentication.USER_TYPES.LINKED]}
          component={Disclaimer}
          exact
          path={paths.DISCLAIMER}
          title={routeTitles.MODisclaimer}
    />
    <AuthenticatedRoute
          authUserTypes={[Authentication.USER_TYPES.STANDARD, Authentication.USER_TYPES.LINKED]}
          component={CaseExists}
          exact
          path={paths.CASE_EXISTS}
          title={routeTitles.MOHealthNet}
    />
    <AuthenticatedRoute
          authUserTypes={[Authentication.USER_TYPES.STANDARD, Authentication.USER_TYPES.LINKED]}
          component={YourBenefits}
          exact
          path={paths.BENEFITS}
          title={routeTitles.headerYourBenefitsLabel}
    />
    {/* UA Routes */}
    <UARoutes />
  </Switch>
);
