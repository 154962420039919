/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2020. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

/**
 * Expected browser name format:
 * https://github.com/lancedikson/bowser/blob/master/src/constants.js
 */
export default {
  safari: '>=13',
  chrome: '>=78',
  edge: '>=18',
  'internet explorer': '>=11',
  firefox: '>=70',
};
