export default {
  defaultLocale: 'en',
  locales: [
    {
      locale: 'en',
      displayName: 'English',
      localeData: () => {
        require('@formatjs/intl-pluralrules/locale-data/en');
        require('@formatjs/intl-relativetimeformat/locale-data/en');
      },
      messages: require('../locale/messages_en'),
    }
  ]
};

/*
FOR OOTB Language selections

{
      locale: 'fr',
      displayName: 'French',
      localeData: () => {
        require('@formatjs/intl-pluralrules/locale-data/fr');
        require('@formatjs/intl-relativetimeformat/locale-data/fr');
      },
      messages: {
        ...require('@spm/core-ui-locales/data/messages_fr'),
        ...require('@spm/intelligent-evidence-gathering-locales/data/messages_fr'),
        ...require('@spm/universal-access-ui-locales/data/messages_fr'),
        ...require('../locale/messages_fr')
      }
    }

*/