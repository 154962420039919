/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2018. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { ProfileModel, Authentication } from '@spm/universal-access';
import { withRouter, Link as RouterLink } from 'react-router-dom';
import {
  Button,
  Header,
  PrimaryNavigation,
  SecondaryNavigation,
  Tabs,
  Tab,
} from '@govhhs/govhhs-design-system-react';
import logo from '../../../images/mydss-logo.png'; //MO CHANGE -- This is the MO Logo for myDSS instead of OOTB
import translations from './ApplicationHeaderMessages';
import SignInMenuComponent from './SignInMenuComponent';
import PATHS from '@spm/universal-access-ui/src/router/Paths';
import * as ApplicationHeaderLogic from './ApplicationHeaderLogic';
import { ID, NAVIGATION_HEADER_TABS } from '../constants';
//import featureToggle from '@spm/universal-access-ui/src/features/featureToggles';

/**
 * Returns true if the Your Account tab is active for the current url pathname. If
 * true the primary navigation contains specific navigation tabs.
 *
 * @param {*} urlPathname current url pathname.
 * @returns true if tab should be active.
 */
const isYourAccountTabActive = urlPathname =>
  ApplicationHeaderLogic.isTabActiveForUrlPathname(
    urlPathname,
    NAVIGATION_HEADER_TABS.YOUR_ACCOUNT.NAME
  );

/**
 * Returns the ApplicationHeaderComponent.
 *
 * @param {*} props props passed to this component.
 * @returns {Component} ApplicationHeaderComponent.
 */
const ApplicationHeaderComponent = props => {
  const {
    intl,
    location,
    profile: userProfile,
    hasErrorBeenCaught,
    history,
    //isUserCanAppeal,
    //isAppealsEnabled,
    isALinkedUser,
    isEmpty,
    mainContentId,
  } = props;

  const { formatMessage } = intl;
  const { pathname: currentUrlPathname } = location;
  const { getActivePrimaryNavigationTabId, getActiveSecondaryNavigationTabId } =
    ApplicationHeaderLogic;

  const marginDirection = document.dir === 'rtl' ? { marginRight: 'auto' } : { marginLeft: 'auto' };

  // const appealsTab = isUserCanAppeal ? (
  //   <Tab
  //     data-testid={NAVIGATION_HEADER_TABS.APPEALS.ID}
  //     id={NAVIGATION_HEADER_TABS.APPEALS.ID}
  //     label={formatMessage(translations.headerAppealsLabel)}
  //     link={<RouterLink to={PATHS.APPEALS.ROOT} />}
  //   />
  // ) : (
  //   ''
  // );

  const skipProps = {};
  if (mainContentId) {
    skipProps.mainContentId = mainContentId;
    skipProps.skipNavigationLinkText = intl.formatMessage(translations.skipNav);
  }

  if (isEmpty) {
    return (
      <Header
        key="application-header"
        data-testid={ID.APPLICATION_HEADER_COMPONENT_ID}
        logo={<img alt="agency logo" aria-hidden id="sso-verifier-header" src={logo} />}
        menuHidden
        title={formatMessage(translations.headerLandingPageTitle)}
        titleHoverText={formatMessage(translations.headerHomeLabel)}
        type="scrollable"
        {...skipProps}
      >
        <span />
      </Header>
    );
  }
  return (
    <Header
      key="application-header"
      data-testid={ID.APPLICATION_HEADER_COMPONENT_ID}
      link={<RouterLink to={PATHS.HOME} />}
      logo={
        <img
          alt="agency logo"
          aria-hidden
          data-testid={ID.APPLICATION_HEADER_HOME_LINK_ID}
          id={ID.APPLICATION_HEADER_HOME_LINK_ID}
          src={logo}
        />
      }
      menuButtonTitle={formatMessage(translations.headerMenuLabel)}
      title={formatMessage(translations.headerLandingPageTitle)}
      titleHoverText={formatMessage(translations.headerHomeLabel)}
      type="scrollable"
      {...skipProps}
    >
      <PrimaryNavigation type="scrollable">
        {isYourAccountTabActive(currentUrlPathname) && (
          <Tabs
            key="primary-tabs"
            ariaLabel="primary-tabs"
            current={getActivePrimaryNavigationTabId(currentUrlPathname)}
            scrollable
          >
            <Tab
              data-testid={NAVIGATION_HEADER_TABS.DASHBOARD.ID}
              id={NAVIGATION_HEADER_TABS.DASHBOARD.ID}
              label={formatMessage(translations.headerDashboardLabel)}
              link={<RouterLink to={PATHS.ACCOUNT} />}
            />
            <Tab
              data-testid={NAVIGATION_HEADER_TABS.YOUR_BENEFITS.ID}
              id={NAVIGATION_HEADER_TABS.YOUR_BENEFITS.ID}
              label={formatMessage(translations.headerYourBenefitsLabel)}
              link={<RouterLink to={PATHS.BENEFITS} />}
            />
            {/* {featureToggle.verifications.isEnabled() && isALinkedUser() && (
              <Tab
                id={NAVIGATION_HEADER_TABS.VERIFICATIONS.ID}
                label={formatMessage(translations.headerYourVerificationsText)}
                link={<RouterLink to={PATHS.VERIFICATIONS.ROOT} />}
              />
            )} */}
             {isALinkedUser() && (
            <Tab
              data-testid={NAVIGATION_HEADER_TABS.PROFILE.ID}
              id={NAVIGATION_HEADER_TABS.PROFILE.ID}
              label={formatMessage(translations.headerProfileText)}
              link={<RouterLink to={PATHS.PROFILE} />}
            />
             )}
            {/* {isALinkedUser() && (
              <Tab
                data-testid={NAVIGATION_HEADER_TABS.NOTICES.ID}
                id={NAVIGATION_HEADER_TABS.NOTICES.ID}
                label={formatMessage(translations.headerNoticesLabel)}
                link={<RouterLink to={PATHS.NOTICES.ROOT} />}
              />
            )} */}
            {/* {isAppealsEnabled && appealsTab} */}
          </Tabs>
        )}
      </PrimaryNavigation>
      <SecondaryNavigation type="scrollable">
        <Tabs
          key="secondary-tabs"
          ariaLabel="secondary-tabs"
          className="wds-u-mr--small"
          current={getActiveSecondaryNavigationTabId(currentUrlPathname)}
        >
          { /* BEGIN MEDES Disable Home outer tab for those not logged in */}
          { userProfile && (
          <Tab
            data-testid={NAVIGATION_HEADER_TABS.HOME.ID}
            id={NAVIGATION_HEADER_TABS.HOME.ID}
            label={formatMessage(translations.headerHomeLabel)}
            link={<RouterLink to={PATHS.HOME} />}
          />)
          }
          {/* END MEDES Disable Home outer tab for those not logged in */}
          {/*
            Remove Benefits Tab at the top selection 
          <Tab
            data-testid={NAVIGATION_HEADER_TABS.BENEFITS.ID}
            id={NAVIGATION_HEADER_TABS.BENEFITS.ID}
            label={formatMessage(translations.headerBenefitsLabel)}
            link={<RouterLink to={PATHS.APPLY} />}
          /> */}
          {userProfile && (
            <Tab
              data-testid={NAVIGATION_HEADER_TABS.YOUR_ACCOUNT.ID}
              id={NAVIGATION_HEADER_TABS.YOUR_ACCOUNT.ID}
              label={formatMessage(translations.headerYourAccountLabel)}
              link={<RouterLink to={PATHS.ACCOUNT} />}
            />
          )}
        </Tabs>
        {/* Show profile menu or signed out menu */}
        {userProfile ? (
          <Button
            key="logout"
            category="secondary"
            className="wds-u-mr--small"
            data-testid={ID.PROFILE_MENU_LOGOUT_BUTTON_ID}
            onClick={() => {
              if (location.pathname !== PATHS.HOME) {
                history.push(PATHS.HOME);
              }
              Authentication.logout();
            }}
            size="small"
            style={marginDirection}
          >
            {formatMessage(translations.headerLogOutText)}
          </Button>
        ) : (
          !hasErrorBeenCaught && <SignInMenuComponent />
        )}
      </SecondaryNavigation>
      {!userProfile && !hasErrorBeenCaught && (
        <Button
          key="login"
          category="link"
          className="wds-u-hide--nav-width-up"
          data-testid={ID.APPLICATION_HEADER_LOGIN_LINK_ID}
          onClick={e => {
            // This is the mobile screen login button
            e.preventDefault();
            props.history.push({
              pathname: PATHS.LOGIN,
              params: { previousPathname: location.pathname },
            });
          }}
          style={marginDirection}
        >
          {formatMessage(translations.headerLogInText)}
        </Button>
      )}
    </Header>
  );
};

/**
 * The ApplicationHeaderComponent properties.
 *
 * See https://reactjs.org/docs/react-component.html#props
 *
 * @memberof ApplicationHeaderComponent
 */
ApplicationHeaderComponent.propTypes = {
  hasErrorBeenCaught: PropTypes.bool,
  history: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  isALinkedUser: PropTypes.func.isRequired,
  isAppealsEnabled: PropTypes.bool.isRequired,
  isEmpty: PropTypes.bool,
  isUserCanAppeal: PropTypes.bool,
  location: PropTypes.object.isRequired,
  mainContentId: PropTypes.string,
  profile: PropTypes.instanceOf(ProfileModel),
};

/**
 * The ApplicationHeaderComponent properties.
 *
 * See https://reactjs.org/docs/react-component.html#defaultprops
 *
 * @memberof ApplicationHeaderComponent
 */
ApplicationHeaderComponent.defaultProps = {
  hasErrorBeenCaught: false,
  isEmpty: false,
  isUserCanAppeal: false,
  mainContentId: null,
  profile: null,
};

export default withRouter(injectIntl(ApplicationHeaderComponent));
