/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2024. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

/**
 * Models the NewMOUASecretQuestionPasswordReset
 *
 * @class NewMOUASecretQuestionPasswordReset
 */
class NewMOUASecretQuestionPasswordReset {
  /**
   * Creates an instance of NewMOUASecretQuestionPasswordReset.
   * @param {*} json
   * @memberof NewMOUASecretQuestionPasswordReset
   */
  constructor(json = null) {
    if (json) {
      // string
      this.username = json.username;

      // string
      this.secret_question_type = json.secret_question_type;

      // string
      this.new_password = json.new_password;

      // string
      this.confirm_new_password = json.confirm_new_password;

      // string
      this.secret_question_answer = json.secret_question_answer;
    }
  }
}

/**
 * Models the MOCheckUsernameBoolean
 *
 * @class MOCheckUsernameBoolean
 */
class MOCheckUsernameBoolean {
  /**
   * Creates an instance of MOCheckUsernameBoolean.
   * @param {*} json
   * @memberof MOCheckUsernameBoolean
   */
  constructor(json = null) {
    if (json) {
      // boolean
      this.usernameExists = json.usernameExists;
    }
  }
}

export default {
  NewMOUASecretQuestionPasswordReset,

  MOCheckUsernameBoolean,
};
